body {
    cursor: inherit;
  }
  
  .cursor-outer {
    margin-left: -12px;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    border: 2px solid red;
    box-sizing: border-box;
    z-index: 10000000;
    opacity: 0.5;
    transition: all 0.08s ease-out;
    mix-blend-mode: difference;
  }
  .cursor-outer.cursor-hover {
    opacity: 0;
  }
  .cursor-outer.cursor-big {
    opacity: 0;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .cursor-outer {
      display: none;
    }
  }
  
  .mouseCursor {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: 50%;
    transform: translateZ(0);
    visibility: hidden;
    text-align: center;
  }
  .mouseCursor.cursor-big {
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .mouseCursor {
      display: none;
    }
  }
  
  .cursor-inner {
    margin-left: -3px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    z-index: 10000001;
    background-color:#fff;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
    mix-blend-mode: difference;
  }
  .cursor-inner span {
    color: #000000;
    line-height: 80px;
    opacity: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
  }
  .cursor-inner.cursor-big span {
    opacity: 1;
  }
  .cursor-inner.cursor-hover {
    margin-left: -12px;
    margin-top: -12px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    opacity: 0;
  }
  @media only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .cursor-inner {
      display: none;
    }
  }