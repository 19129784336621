@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style-type: none;
}

:root {
   --orange-color: #FF6B00;
  --primary: #7f56d9;
  --secondary: #f4ebff;
  --text-primary: #101828;
  --text-secondary: #667085;
  --badge-bg: #ecfdf3;
  --badge-text: #027a48;
  --white: #fff;
  --dropdown-bg: rgb(252, 253, 251);
  --shadow: rgba(32, 7, 65, 0.14);
  --container: 135rem;
  --nav-height: 72px;

}

html {
  font-family: "Inter";
  font-size: 62.5%;
  font-style: normal;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
  background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);;

}
/* Scrollbar Styles */

/* width */
::-webkit-scrollbar {
  width: 5px; /* Increased width for better visibility */
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(249, 245, 255); 
  border-radius: 10px; /* Rounded corners for the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--orange-color); 
  border-radius: 10px; /* Rounded corners for the handle */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5a3e99; /* Darker color on hover for better feedback */
}

/* Handle on active (when clicked) */
::-webkit-scrollbar-thumb:active {
  background: #4b327f; /* Even darker color when active */
}

/* Scrollbar corner for when both horizontal and vertical scrollbars are present */
::-webkit-scrollbar-corner {
  background: rgb(249, 245, 255); /* Match the track background color */
}

.c-p {
  cursor: pointer;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../Assets/Fonts/Montserrat/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../Assets/Fonts/Montserrat/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("../../Assets/Fonts/Montserrat/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
.orange-btn {
    font-family: "Inter";
    color: var(--Gray-100, #f2f4f7);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 10px 32px;
    background-color: var(--orange-color);
    border-radius: 6px;
    font-size: 21px;
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid transparent;
    transition: all .3s;
    cursor: pointer;
 
}
/* .orange-btn:hover{
    background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);;
 
} */
.breadcrumb-item {
  color: var(--Gray-600, #475467);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

a {
  text-decoration: none;
  color: var(--Gray-100, #f2f4f7);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

ul {
  margin-bottom: 0px;
  padding-left: 0px;
}

.header-container {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
}

.layout {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 100%;
}

.navigation {
    padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--nav-height);
  position: relative;
  border-bottom: 1px solid var(--Gray-600, #475467);
  background: var(--Gray-950, #0c111d);
}

.nav_list {
  display: inline-flex;
  gap: 2rem;
  align-items: center;
  margin: 0 1.5rem;
  color: var(--Gray-600, #475467);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.nav_link:hover {
  color: var(--Gray-100, #f2f4f7);
  border-bottom: 1px solid var(--orange-color);
}

.nav_action {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav_link {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  font-family: "Inter";
  color: var(--Gray-100, #f2f4f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.nav_menu .nav_list .nav_link.active {
  border-radius: 6px;
  background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);;
 
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
}

.btn-primary:hover {
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7f56d9);
  background: var(--Primary-600, #7f56d9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.icn-bc {
  border-radius: 28px;
  border: 8px solid var(--Primary-50, #f9f5ff);
  background: var(--Primary-100, #f4ebff);
}

.btn-primary {
  display: inline-flex;
  color: var(--white);
  background: var(--primary);
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7f56d9);
  background: var(--Primary-600, #7f56d9);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Base-White, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.nav_toggle {
  cursor: pointer;
  display: none;
}

.nav_toggle ion-icon {
  font-size: 3.5rem;
  color: var(--text-primary);
}

.item-heading {
  color: var(--Primary-600, #7f56d9);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

@media (max-width: 730px) {
    .nav_toggle {
      display: block;
    
        color: #fff;
        background: var(--orange-color);
        padding: 12px;
        border-radius: 50%;
   
    }
  
    .nav_menu {
      position: absolute;
      top: var(--nav-height);
      left: 0;
      width: 100%;
      background: var(--Gray-950, #0c111d);
      display: none;
      flex-direction: column;
      align-items: center;
      padding: 1rem 0;
    }
  
    .nav_menu.active {
      display: flex;
    }
  
    .nav_list {
      display: block;
      margin: 1rem 0;
      text-align: center;
    }
  
    .nav_link {
      padding: 1rem;
      color: var(--Gray-100, #f2f4f7);
    }
  }
  
  @media (max-width: 365px) {
    .logo-img {
      width: 10rem;
    }
  
    .btn,
    .btn-primary {
      padding: 0.4rem 1rem;
    }
  }
  
  /* mui css */
  /* strpper */
  .css-2fdkz6 {
    color: var(--Gray-700, #344054) !important;
    font-family: "Inter" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
  
  .modal-100w {
    max-width: 85%;
    left: 5%;
  }
  
  .modal-100w .modal-dialog {
    width: 85%;
    max-width: 100%;
    margin: 1.75rem auto;
  }
  
  .modal-100w .modal-content {
    width: 85%;
    border-radius: 12px;
    padding: 24px;
  }
  
  .modal-title {
    color: var(--Gray-900, #101828);
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  
  .modal-header {
    border-bottom: none !important;
  }
  
  .input-group > .form-control,
  .input-group > .form-select {
    border-radius: 8px;
    outline: none;
  }
  

  .allcourse-header{
    background: #0c111d;
    background-image: url('../../Assets/Images/Background\ pattern\ \(1\).png');
   
    background-position: center bottom;
    
    background-size:cover

}
.library-button {
    padding: 16px 28px;
justify-content: center;
align-items: center;
    border-radius: 8px;
border: 1px solid var(--Gray-300, #D0D5DD);
background: var(--Base-White, #FFF);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text lg/Semibold */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.section-heading{
    color: var(--Gray-900, #101828);
text-align: center;

/* Display md/Semibold */
font-family: "Inter";
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.section-sub-heading{
    color: var(--Gray-600, #475467);
text-align: center;

/* Text xl/Regular */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 150% */
}
.course-layout{
    display: flex;
    padding: 52px 0px 0px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 14px;
    align-self: stretch;
}
.parrent {

    padding: 64px 0px 96px 0px;
  
    justify-content: center;
  
  }
  .ph {
    color: var(--Gray-900, #101828);
  
    /* Display xl/Semibold */
    font-family: Inter;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
    /* 120% */
    letter-spacing: -1.2px;
  
  }
  
  .pt {
    color: var(--Gray-600, #475467);
  
    /* Text xl/Regular */
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 150% */
    /* text-align: justify; */
  }
  
  /* client */
  .section-header {
    color: var(--Gray-900, #101828);
    text-align: center;
  
    /* Display md/Semibold */
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    /* 122.222% */
    letter-spacing: -0.72px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
}
.project-feature h6{
  margin: 20px 0px; /* Adjust spacing as needed */
  font-size: 24px; /* Adjust font size as needed */
  white-space: nowrap;
  color: var(--orange-color);
 margin-bottom: 10px;
}
.offcanvas-bg{
  background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);
}
.offcanvas-list  li{
  list-style: inside;
 margin-bottom: 10px;

}
  .title h4{
    margin: 0 20px; /* Adjust spacing as needed */
    font-size: 24px; /* Adjust font size as needed */
    white-space: nowrap;
    color: var(--orange-color);
 

/* Text md/Semibold */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.line {
    flex-grow: 1;
    height: 2px; /* Adjust height as needed */
    background-color: #FF6B00; /* Adjust color as needed */
}
.b-bottom {
    width: 50%; /* Adjust width as needed */
    height: 4px; /* Adjust height as needed */
    background-color: var(--orange-color); /* Adjust color as needed */
    margin: 0 auto;
    margin-top: 10px; /* Adjust space between text and line */
}
.feature-screen-header{
    color: var(--Gray-900, #101828);

/* Display md/Semibold */
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 44px; /* 122.222% */
letter-spacing: -0.72px;
}
.feature-screen-text{
    color: var(--Gray-600, #475467);

    /* Text xl/Regular */
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
}
.main-feature{
    display: flex;

padding: 0px 32px;
align-items: center;
gap: 64px;
}
.f1h1{
   
    color: var(--Gray-900, #101828);

/* Text xl/Semibold */
font-family: "Inter";
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
}

.f1p1{
    color: var(--Gray-600, #475467);

/* Text md/Regular */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.btn-explore:hover{
  color: #FFF;
}
.btn-explore{
    display: flex;
padding: 12px 20px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);

/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.sticky-div {

    box-shadow: 0px 10px 10px 0px rgba(16, 24, 40, 0.05);
    background: var(--Base-White, #FFF);
    /* padding: 10px; */
    position: sticky;
    top: 0; z-index: 999; 
  }
  .sticky-div-create-question{
    box-shadow: 0px 10px 10px 0px rgba(16, 24, 40, 0.05);
    background: var(--Base-White, #FFF);
    padding: 10px;
    position: sticky;
    top: 0; z-index: 999; 
  }

  /* Card */
 
 .pmd-card-media{
  background: #1abc9c;
 }
  .pmd-card-media img {
    opacity: 1;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  .pmd-card-media:hover img {
    opacity: .5;
  }
 
.card-link{
  color: var(--orange-color);
  
}
.card-link:hover {
  text-decoration: underline;
}

.card-icon {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
}
.card-body{
  padding: 16px 24px;
}
.card-main {
 
  border-radius: 8px;
  background: var(--Gray-25, #fcfcfd);
  
  /* Shadow/sm */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.card-main:hover{
  background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);;
  ;
  border: 1px solid var(--orange-color);
}

.card-title {
  color: var(--Gray-900, #101828);

  /* Text md/Semibold */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  
}
.card-text {
  color: var(--Gray-600, #475467);

  /* Text sm/Regular */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

/* .signup-form {
	max-width: 400px;
	margin: 0 auto;
	padding: 30px 0;		
} */
/* .signup-form form {
	color: #999;
	border-radius: 3px;
	margin-bottom: 15px;
	background: #fff;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	padding: 30px;
} */

.signup-form h3:before, .signup-form h3:after {
	content: "";
	height: 2px;
	width: 40%;
	background: #d4d4d4;
	position: absolute;
	
	z-index: 2;
}
.signup-form h3 {
	color: #636363;
	margin: 0 0 15px;
	position: relative;
	text-align: center;
}
.signup-form h3:before {
	left: 0;
}
.signup-form h3:after {
	right: 0;
}	
.signup-form h2 {
	color: var(--Gray-900, #101828);
text-align: center;

/* Display sm/Semibold */
font-family: "Inter";
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 38px; /* 126.667% */
}
.signup-form p{
    color: var(--Gray-600, #475467);
text-align: center;

/* Text md/Regular */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
.signup-form hr {
	margin: 0 -30px 20px;
}
.signup-form .form-group {
	margin-bottom: 15px;
}
.signup-form label {
	font-weight: normal;
	font-size: 15px;
}
.signup-form .form-control {
	min-height: 38px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}	
.signup-form .form-group label{
    color: var(--Gray-700, #344054);

/* Text sm/Medium */
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
padding-bottom: 10px;
}
.signup-form .input-group-addon {
	max-width: 42px;
	text-align: center;
}	
.signup-form .btn, .signup-form .btn:active {        
	display: flex;
padding: 10px 18px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid var(--Primary-600, #7F56D9);
background: var(--Primary-600, #7F56D9);
width: 100%;
/* Shadow/xs */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Base-White, #FFF);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}

.signup-form .btn-login, .signup-form .btn-login:active { 
    display: flex;
    width: 100%;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 12px;
align-self: stretch;       
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
color: var(--Gray-700, #344054);

/* Text md/Semibold */
font-family: "Inter";
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */

}
.form-control {
	font-size: 15px;
}
.form-control, .form-control:focus, .input-group-text {
	border-color: #e1e1e1;
}
.form-control, .btn {        
	border-radius: 3px;
}

/* footer */

.footer-section {
  background: #0C111D;
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: var(--Gray-200, #EAECF0);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;

  font-family: 'Inter';
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  /* left: 0; */
  bottom: -15px;
  height: 2px;
  /* width: 50px; */
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 100%;
  margin-bottom: 12px;

}

/* .footer-widget ul li a:hover{
    color: #ff5e14;
  } */
.footer-widget ul li a {
  color: #EAECF0;
  text-transform: capitalize;
  text-decoration: none;
  font-family: 'Inter';
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  /* color: #fff; */
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: var(--Base-White, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.subscribe-form button {
  /* position: absolute; */
  right: 0;

  padding: 13px 20px;

  top: 0;
  color: var(--Base-White, #FFF);

  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
  border-radius: 8px;
  border: 1px solid var(--Primary-600, #7F56D9);
  background: var(--Primary-600, #7F56D9);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #0C111D;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

/* .footer-menu li:hover a{
    color: #ff5e14;
  } */
.footer-menu li a {
  color: var(--Gray-300, #D0D5DD);

  /* Text md/Regular */
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}


/* skills */

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
  border-radius: 8px;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
  display: inline-block;
  animation: slide 35s infinite linear;
  /* Adjust 35s to your preferred animation duration */

}

.logos-slide img {
  
  opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
  height: 50px;
  margin: 0 40px;
  flex: 0 0 auto;
 
}
.logos-slide img:hover{
  background: linear-gradient(256.24deg, rgba(255, 195, 152, 0.2) 26.56%, rgba(255, 234, 190, 0.3) 100%);;

  opacity: 0.5;
}


/* page Not found  */


/*======================
    404 page
=======================*/


.page_404 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  margin: auto;
  height: 100vh; /* Make the container take the full height of the viewport */
}



.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    mix-blend-mode: multiply;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{	
        border-radius: 12px;		 
	color: #fff!important;
    padding: 10px 20px;
    background: var(--orange-color);
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}